import React, { useEffect } from 'react'
import { Box, useColorMode } from '@chakra-ui/react'
import StaticHeader from '~components/navbar/static-header'
import Footer from '~components/footer/footer.tsx'
import { useLocation } from '@reach/router'

export default function Layout({ children }) {
    const { setColorMode } = useColorMode()
    const pathname = useLocation().pathname
    useEffect(() => {
        setColorMode('light')
    }, [])

    return (
        <Box>
            {children}
            <Footer />
            {!pathname.includes('/casestudy') && <StaticHeader />}
        </Box>
    )
}
