import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { forwardRef, Box, Flex } from '@chakra-ui/react'

const MotionBox = motion(Box)

export default function HamburgerMenu({
    onClick,
    isOpen,
    setIsOpen,
    height = '40px',
    width = '40px',
}) {
    const controls1 = useAnimation()
    const controls2 = useAnimation()
    const controls3 = useAnimation()

    useEffect(() => {
        if (isOpen) {
            controls1.start({
                rotate: 45,
                y: parseInt(height) / 3,
                transition: { duration: 0.3 },
            })

            controls2.start({
                opacity: 0,
                transition: { duration: 0.1 },
            })

            controls3.start({
                rotate: -45,
                y: -parseInt(height) / 3,
                transition: { duration: 0.3 },
            })
        } else {
            controls1.start({ rotate: 0, y: 0 })
            controls2.start({ opacity: 1 })
            controls3.start({ rotate: 0, y: 0 })
        }
    }, [isOpen, controls1, controls2, controls3])

    function handleClick() {
        onClick()
        setIsOpen(!isOpen)
    }

    return (
        <Flex
            as="button"
            onClick={() => handleClick()}
            onKeyDown={() => handleClick()}
            direction="column"
            justify="space-around"
            h={height}
            w={width}
        >
            <MotionBox
                animate={controls1}
                w="100%"
                h="10%"
                bg="brand.500"
                borderRadius="10px"
            />
            <MotionBox
                animate={controls2}
                w="100%"
                h="10%"
                bg="brand.500"
                borderRadius="10px"
            />
            <MotionBox
                animate={controls3}
                w="100%"
                h="10%"
                bg="brand.500"
                borderRadius="10px"
            />
        </Flex>
    )
}
