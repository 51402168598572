import React from 'react'
import PropTypes from 'prop-types'

import Layout from '~components/shared/layout'

const WrapPageElement = ({ element, props }) => {
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return <Layout {...props}>{element}</Layout>
}

WrapPageElement.propTypes = {
    element: PropTypes.element,
    props: PropTypes.object,
}

export default WrapPageElement
