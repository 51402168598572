import React from 'react'
import { Link } from 'gatsby'
import { motion, isValidMotionProp } from 'framer-motion'
import {
    forwardRef,
    Box,
    Flex,
    Heading,
    Image,
    Text,
    SimpleGrid,
    Link as ELink,
} from '@chakra-ui/react'

import ResponsiveBlock from '~components/shared/responsive-block'
import facebook from '~images/socials/facebookicon.svg'
import instagram from '~images/socials/instagramicon.svg'
import linkedin from '~images/socials/linkedinicon.svg'
import twitter from '~images/socials/twittericon.svg'
import breachWhite from '~images/logos/full_text_all_white.svg'

const MotionInternalLink = motion(Link, { forwardMotionProps: true })

const MotionExternalLink = motion(ELink, { forwardMotionProps: true })

const Footer = () => {
    return (
        <Box>
            <Box
                h={{ base: '100vh', md: '596px' }}
                bg="black"
                pt={{ base: 20, md: 12 }}
                pb={{ base: 8, md: 12 }}
            >
                <ResponsiveBlock>
                    <Flex
                        w="100%"
                        h="100%"
                        direction={{ base: 'column', md: 'row' }}
                    >
                        <Flex
                            w={{ base: '100%', md: '60%' }}
                            h="100%"
                            pl={{ base: 4, md: '10%' }}
                            justify="center"
                            direction="column"
                        >
                            <Flex
                                justify="flex-start"
                                h={{ base: '65px', md: '84px' }}
                            >
                                <Image
                                    w={{ base: '156px', md: '232px' }}
                                    mb={6}
                                    src={breachWhite}
                                />
                            </Flex>
                            <Flex
                                h={{ base: '100%', md: '60%' }}
                                direction="column"
                                justify="space-around"
                            >
                                <Text
                                    color="white"
                                    fontSize={{ base: '2xs', md: 'xs' }}
                                >
                                    8000 Innovation Park Dr.
                                    <br />
                                    Baton Rouge, LA, 70820
                                </Text>
                                <Text
                                    color="white"
                                    fontSize={{ base: '2xs', md: 'xs' }}
                                >
                                    Inquiries: (225) 610-0701
                                </Text>
                                <Text
                                    color="white"
                                    fontSize={{ base: '2xs', md: 'xs' }}
                                >
                                    Support: (337) 304-4448
                                </Text>
                                <Text
                                    color="white"
                                    fontSize={{ base: '2xs', md: 'xs' }}
                                >
                                    contact@breachsoftware.com
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex
                            h="100%"
                            w={{ base: '100%', md: '50%' }}
                            align="center"
                        >
                            <SimpleGrid
                                w="100%"
                                h={{ base: '100%', md: '70%' }}
                                pl={{ base: 4, md: 0 }}
                                pt={{ base: 12, md: 6 }}
                                columns={{ base: 1, md: 3 }}
                            >
                                <MotionInternalLink
                                    to="/"
                                    whileHover={{ y: -5 }}
                                >
                                    <Flex w="100%" h="100%" align="center">
                                        <Heading
                                            color="white"
                                            size="xs"
                                            fontSize={{ base: 'xs', md: 'sm' }}
                                        >
                                            Home
                                        </Heading>
                                    </Flex>
                                </MotionInternalLink>
                                <MotionInternalLink
                                    to="/services"
                                    whileHover={{ y: -5 }}
                                >
                                    <Flex w="100%" h="100%" align="center">
                                        <Heading
                                            color="white"
                                            size="xs"
                                            fontSize={{ base: 'xs', md: 'sm' }}
                                        >
                                            Services
                                        </Heading>
                                    </Flex>
                                </MotionInternalLink>
                                <MotionInternalLink
                                    to="/contact"
                                    whileHover={{ y: -5 }}
                                >
                                    <Flex w="100%" h="100%" align="center">
                                        <Heading
                                            color="white"
                                            size="xs"
                                            fontSize={{ base: 'xs', md: 'sm' }}
                                        >
                                            Contact
                                        </Heading>
                                    </Flex>
                                </MotionInternalLink>

                                <MotionInternalLink
                                    to="/about"
                                    whileHover={{ y: -5 }}
                                >
                                    <Flex w="100%" h="100%" align="center">
                                        <Heading
                                            color="white"
                                            size="xs"
                                            fontSize={{ base: 'xs', md: 'sm' }}
                                        >
                                            About
                                        </Heading>
                                    </Flex>
                                </MotionInternalLink>
                                <MotionInternalLink
                                    to="/projects"
                                    whileHover={{ y: -5 }}
                                >
                                    <Flex w="100%" h="100%" align="center">
                                        <Heading
                                            color="white"
                                            size="xs"
                                            fontSize={{ base: 'xs', md: 'sm' }}
                                        >
                                            Projects
                                        </Heading>
                                    </Flex>
                                </MotionInternalLink>
                                <MotionInternalLink
                                    to="/privacy"
                                    whileHover={{ y: -5 }}
                                >
                                    <Flex w="100%" h="100%" align="center">
                                        <Heading
                                            color="white"
                                            size="xs"
                                            fontSize={{ base: 'xs', md: 'sm' }}
                                        >
                                            Privacy Policy
                                        </Heading>
                                    </Flex>
                                </MotionInternalLink>
                            </SimpleGrid>
                        </Flex>
                    </Flex>
                </ResponsiveBlock>
            </Box>
            <Box pt={12} pb={16} bg="black" borderTop="1px solid grey">
                <ResponsiveBlock>
                    <Flex
                        px="10%"
                        w={{ base: '100%', md: '35%' }}
                        justify="space-between"
                    >
                        <MotionExternalLink
                            href="https://www.facebook.com/breachsoftware"
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 1, y: 1 }}
                            isExternal
                        >
                            <Image boxSize="32px" src={facebook}></Image>
                        </MotionExternalLink>
                        <MotionExternalLink
                            href="https://www.instagram.com/breachsoftware/"
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 1, y: 1 }}
                            isExternal
                        >
                            <Image boxSize="32px" src={instagram}></Image>
                        </MotionExternalLink>
                        <MotionExternalLink
                            href="https://www.linkedin.com/company/breach-software"
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 1, y: 1 }}
                            isExternal
                        >
                            <Image boxSize="32px" src={linkedin}></Image>
                        </MotionExternalLink>
                        <MotionExternalLink
                            href="https://twitter.com/breachsoftware"
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 1, y: 1 }}
                            isExternal
                        >
                            <Image boxSize="32px" src={twitter}></Image>
                        </MotionExternalLink>
                    </Flex>
                </ResponsiveBlock>
            </Box>
        </Box>
    )
}

export default Footer
