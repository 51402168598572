import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { motion, isValidMotionProp } from 'framer-motion'
import {
    forwardRef,
    Box,
    Flex,
    Center,
    Heading,
    Image,
    SimpleGrid,
} from '@chakra-ui/react'
import { useLocation } from '@reach/router'

import logo from '~images/logos/Breach_Red_B.svg'
import { useScrollPosition } from '~components/shared/scroll-hook'
import HamburgerMenu from '~components/navbar/hamburger-menu-button'
import ResponsiveBlock from '~components/shared/responsive-block'

const MotionLink = motion(Link)

const StaticHeader = () => {
    const [hideOnScroll, setHideOnScroll] = useState(false)
    const [showShadow, setShowShadow] = useState(false)
    const [showNavPage, setShowNavPage] = useState(false)

    useScrollPosition(
        ({ prevPos, currPos }) => {
            const isShow = currPos.y > prevPos.y && currPos.y > 0
            if (isShow !== hideOnScroll) setHideOnScroll(isShow)
            const isShad = currPos.y > 120
            if (currPos !== showShadow) setShowShadow(isShad)
        },
        [hideOnScroll],
        null,
        true,
        0
    )

    return (
        <Box
            top={hideOnScroll && !showNavPage ? -200 : 0}
            transition="0.2s"
            w="100%"
            py={4}
            position="fixed"
            pt={5}
            boxShadow={showShadow ? 'md' : ''}
            bg={showNavPage ? 'transparent' : 'white'}
        >
            <ResponsiveBlock wide>
                <Flex justify="space-between" position="relative">
                    <MotionLink to="/">
                        <Image src={logo} w="45px" top={0} left={0} />
                    </MotionLink>

                    <Box display={{ base: 'none', lg: 'flex' }} w="60%">
                        <LargeHeader />
                    </Box>
                    <Box display={{ base: 'flex', lg: 'none' }}>
                        <MobileHeader
                            showNav={showNavPage}
                            setShowNav={setShowNavPage}
                        />
                    </Box>
                </Flex>
            </ResponsiveBlock>
        </Box>
    )
}

const LargeHeader = () => {
    return (
        <Flex align="center" w="100%" h="100%">
            <NavLinks row />
        </Flex>
    )
}

const MobileHeader = ({ showNav, setShowNav }) => {
    return (
        <Box>
            <NavScreen isOpen={showNav} setIsOpen={setShowNav} />
            <HamburgerMenu
                isOpen={showNav}
                setIsOpen={setShowNav}
                onClick={() => {
                    setShowNav(!showNav)
                }}
            />
        </Box>
    )
}

const NavScreen = ({ isOpen, setIsOpen }) => {
    useEffect(() => {
        isOpen
            ? (document.body.style.overflowY = 'hidden')
            : (document.body.style.overflowY = 'auto')
    }, [isOpen])

    const handleClick = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Box
                w="100vw"
                h="100vh"
                position="fixed"
                top="0"
                bottom="0"
                left="0"
                right="0"
                bg="rgb(255,255,255,1)"
                style={{ backdropFilter: 'saturate(180%) blur(9px)' }}
                display={isOpen ? 'initial' : 'none'}
            >
                <Flex align="center" justify="center" h="60%">
                    <MobileLinks onClick={handleClick} />
                </Flex>
            </Box>
            <MotionLink to="/" onClick={handleClick}>
                <Image
                    src={logo}
                    w="45px"
                    position="absolute"
                    top={0}
                    left={0}
                />
            </MotionLink>
        </>
    )
}

const NavLinks = ({ onClick, row }) => {
    const handleClick = () => {
        if (onClick) onClick()
    }

    const pathname = useLocation().pathname

    const afterStyle = {
        content: `''`,
        width: '100%',
        position: 'absolute',
        bottom: '-5px',
        left: '0',
        height: '2px',
        transformOrigin: 'center',
        backgroundColor: '#f74734',
        transition: '0.2s',
    }

    return (
        <Flex
            direction={row ? 'row' : 'column'}
            align="center"
            justify="space-between"
            h="100%"
            w="100%"
        >
            <SimpleGrid columns={4} w="100%" h="100%">
                {['Services', 'About', 'Projects', 'Contact'].map(pageName => {
                    return (
                        <MotionLink
                            key={pageName}
                            to={`/${pageName.toLowerCase()}`}
                            whileHover={{ y: -2 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={handleClick}
                        >
                            <Center h="100%">
                                <Heading
                                    position="relative"
                                    fontSize="sm"
                                    _after={{
                                        ...afterStyle,
                                        transform: `scaleX(${
                                            pathname ===
                                            `/${pageName.toLowerCase()}`
                                                ? '70%'
                                                : 0
                                        })`,
                                    }}
                                >
                                    {pageName}
                                </Heading>
                            </Center>
                        </MotionLink>
                    )
                })}
            </SimpleGrid>
        </Flex>
    )
}

const MobileLinks = ({ onClick, row }) => {
    const handleClick = () => {
        if (onClick) onClick()
    }

    const pathname = useLocation().pathname

    const afterStyle = {
        content: `''`,
        width: '110%',
        position: 'absolute',
        bottom: '-2px',
        left: '-5%',
        height: '2px',
        transformOrigin: 'center',
        backgroundColor: '#f74734',
    }

    return (
        <Flex
            direction={row ? 'row' : 'column'}
            align="center"
            justify="space-between"
            h="60%"
            w="100%"
        >
            {['Services', 'About', 'Projects', 'Contact'].map(pageName => {
                return (
                    <MotionLink
                        key={pageName + 'm'}
                        to={`/${pageName.toLowerCase()}`}
                        whileHover={{ y: -5 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={handleClick}
                    >
                        <Center h="100%">
                            <Heading
                                position="relative"
                                size="sm"
                                _after={
                                    pathname === `/${pageName.toLowerCase()}` &&
                                    afterStyle
                                }
                            >
                                {pageName}
                            </Heading>
                        </Center>
                    </MotionLink>
                )
            })}
        </Flex>
    )
}

export default StaticHeader
